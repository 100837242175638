<template>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
        <!-- <px-card>
          <template slot="headerCustom"> -->
            <b-row>
              <b-col cols="4">
                <h4 class="f-w-900">Ongoing Incidents</h4>
              </b-col>
              <b-col cols="5" class="mt-1" v-if="!monitor_id">
                  <multiselect variant="success"
                    :close-on-select="true"
                    v-model="selected_monitor"
                    tag-placeholder="Select Monitor" 
                    placeholder="Search by Monitor" 
                    label="text" track-by="value" 
                    :options="monitor_opt" 
                    :multiple="false"
                    @input="FilterIncedance"
                  >
                  </multiselect>
              </b-col>
              <b-col cols="3" class="text-right">
                  <b-form-group
                    label-cols="6"
                    label="Per page"
                    class="mb-0"
                  >
                  <b-form-select
                    v-model="perPage"
                    :options="pageOptions"
                    @change="getIncedent()"
                  ></b-form-select>
                  </b-form-group>
                </b-col>
          </b-row>
          <!-- </template> -->
          <div slot="headerAction"></div>
            <div slot="" class="pb-3 pr-3 pl-3">
              <!-- <h4 class="f-w-900">Ongoing Incidents</h4> -->
              <!-- <b-row class=""> -->
                <!-- <b-col class="text-right">
                  <b-form-group
                    label-cols="8"
                    label="Per page"
                    class="mb-0"
                  >
                    <b-form-select
                      v-model="perPage"
                      :options="pageOptions"
                      @change="getIncedent()"
                    ></b-form-select>
                  </b-form-group>
                </b-col> -->
                <!-- <b-col v-if="monitor_id">
                <b-input-group class="">
                    <b-form-input
                      v-model="filter"
                      placeholder="Type to Search"
                      @input="onCustomFiltered"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''"
                        >Clear</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-col> -->
              <!-- </b-row> -->
              <div class="col-md-12" v-if="loading">
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
              </div> 
              <div v-else class="table-responsive datatable-vue text-left">
                <b-table
                  show-empty
                  stacked="md"
                  :items="incedent_data"
                  :fields="tablefields"
                  @filtered="onFiltered"
                  :per-page="perPage"
                >
                <!-- <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template> -->
                <template #cell(status)="data">
                  <b v-if="data.item.status" class="text-success">Resolved</b>
                  <b v-else class="text-danger">Ongoing</b>
                </template>
                <template #cell(mName)="data">
                  <div title="click here to show more details" style="cursor:pointer;" @click="OpenIncedentDetailModal(data.item)">
                  <!-- <span class="text-primary" v-if="monitor_data.filter((t)=> t.value == data.item.mId)[0]">
                    {{monitor_data.filter((t)=> t.value == data.item.mId)[0].text}}</span> -->
                    <span class="text-primary">{{data.item.mName}}</span>
                  </div>
                </template>
                <template #cell(duration)="data">
                  <span class="">{{convertMsToHM(data.item.duration)}}</span>
                </template>
                <template #cell(createdAt)="data">
                  <span class="">{{changeDateFormate(data.item.createdAt)}}</span>
                </template>
                <!-- <template #cell(details)="data">
                  <span class="link_txt" @click="OpenIncedentDetailModal(data.item)"> details </span>
                </template> -->

                <template #table-caption>Ongoing Incidents.</template>
                </b-table>
              </div>

              <b-col md="6" class="mt-3 p-0">
                <div class="pagination">
                  <a v-show="currentPage != 1" @click="getIncedent('prev')">&laquo; Back</a>
                  <a class="active">{{currentPage}}</a>
                  <a v-show="incedent_data.length >= 1" @click="getIncedent('next')">Next &raquo;</a>
                </div>
              </b-col>
            </div>
        <!-- </px-card> -->
        </div>
      </div>
      <!-- Details Modal  -->
      <b-modal id="modal-monitor" size="xl"  class="theme-modal" v-model="show_details" no-close-on-backdrop hide-footer>
        <template #modal-title>
          <i v-if="incedent_details.status" class="fa fa-dot-circle-o text-success"></i>
          <i v-else class="fa fa-dot-circle-o text-danger"></i>
            Incident on <span class="font-weight-bold">{{incedent_details.mName}}</span>
        </template>

        <incedent-details :incedent_details="incedent_details"></incedent-details>

        <template #modal-footer>
        <b-button @click="show_details = false" color="danger">Close</b-button>
        </template>
      </b-modal>
    </div>
</template>


<script>
import moment from 'moment'
import DashboardController from "../../services/dashboardController";
import HelperFunction from "../../services/helperFunction";
import IncedentDetails from "./IncedentDetails.vue";
import MonitorsController from "../../services/monitorController";
import Multiselect from 'vue-multiselect'
export default {
  name: "Incedent",
  components:{IncedentDetails, Multiselect},
  props:{monitor_id:String,monitor_data:Object,dateRanges:Object,update_filter:Number},
  data() {
    return {
      incedent_details:{},
      incedent_data: [],
      show_details:false,
      filter:null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 50],
      loading:false,
      tablefields: [
      { key: "mName", label: "Name", sortable: true },
      { key: "status", label: "Status", sortable: false },
      { key: "duration", label: "Duration", sortable: false },
      // { key: "comments", label: "Comments", sortable: true },
      { key: "createdAt", label: "Started At", sortable: false },
      ],
    all_incedent_data:[],
    monitor_opt: [{ value: null, text: "All Incidents" }],
    selected_monitor:'',
    monitorId:this.monitor_id
    };
  },
  mounted() {
    this.getMonitors();
    if(this.$route.params.mid){
      this.monitorId = this.$route.params.mid
    }
    this.perPage = 5;
    if(!this.monitor_id){
      this.perPage = 10;
    }
    this.getIncedent();
  },
  watch:{
  async monitor_id(){
      this.currentPage = 1
      this.perPage = 5;
      await this.getIncedent();
    },
  async update_filter(){
      await  this.getIncedent('',this.dateRanges)
    }
  },
  methods: {
    async getMonitors() {
      let response = await MonitorsController.getMonitors();
      if (response.result) {
        response.data.map((t) => {
          this.monitor_opt.push({ value: t._id, text: t.name, type:t.type,isDown:t.isDown });
        });
      }
      this.selected_monitor = this.monitor_opt.filter((t)=> t.value == this.monitorId)[0]
    },
    FilterIncedance(){
      if(this.selected_monitor){
        this.monitorId = this.selected_monitor.value
      }else{
        this.monitorId = null
      }
      this.getIncedent()
    },
    OpenIncedentDetailModal(data){
      this.incedent_details = data
      this.show_details = true
    },
    async getIncedent(txt,dateRange) {
      var mid = this.monitorId?this.monitorId:this.monitor_id
      if(txt == 'next'){this.currentPage ++}
          if(txt == 'prev'){
            if(this.currentPage != 1){
            this.currentPage --
            }
          }
      var payload = '?currentPage='+this.currentPage+'&pageSize='+this.perPage
      if(dateRange){
         payload = '?currentPage='+this.currentPage+'&pageSize='+this.perPage+'&sdate='+dateRange.startDate+'&edate='+dateRange.endDate
       }
      if(mid && mid != null){
         payload = '?mid='+mid+'&currentPage='+this.currentPage+'&pageSize='+this.perPage
         if(dateRange){
         payload = '?mid='+mid+'&currentPage='+this.currentPage+'&pageSize='+this.perPage+'&sdate='+dateRange.startDate+'&edate='+dateRange.endDate
       }
      }
      this.loading = true;
      var response = await DashboardController.getIncedent(payload);
      if (response.result) {
        this.incedent_data = []
        this.all_incedent_data = []
        this.incedent_data = response.data;
        this.all_incedent_data = this.incedent_data;
        this.totalRows = this.incedent_data.length;
      }
      this.loading = false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  onCustomFiltered() {
    this.incedent_data = HelperFunction.onCustomFiltered(this.all_incedent_data, this.incedent_data, this.filter)
  },
    changeDateFormate(d){
        return moment(d).format('D/M/YYYY, HH:mm')
    },
    convertMsToHM(millis) {
      return HelperFunction.convertMsToHM(millis)
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  font-size: 12px;
}

.pagination a.active {
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
}

.pagination a:hover:not(.active) {background-color: #ddd; cursor: pointer;}
</style>
